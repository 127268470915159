// Mutation
/* eslint-disable */
export const SET_VENDOR = "setVendor"
export const SET_ERROR = "setError"
export const STORE_VENDOR = "storeVendor"
export const UPDATE_VENDOR = "updateVendor"
export const GET_VENDOR = "getVendor"
export const DELETE_VENDOR = "deleteVendor"
export const IMPORT_VENDOR = "importVendor"
export const SEND_MAIL = "sendMail";

export const SET_SINGLE_VENDOR = "setSingleVendor"

import ApiService from "@/core/services/api.service"

const state = {
    vendorList: {},
    vendor: {},
    errors: null,
};

const getters = {
    currentVendorList(state) {
        return state.vendorList;
    },
    currentVendor(state) {
        return state.vendor;
    }
};

const actions = {
    [STORE_VENDOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`vendor`, payload.body)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message)
                    reject()
                })
        })
    },
    [GET_VENDOR](context, payload) {
        return new Promise((resolve, reject) => {
            console.log(payload)
            ApiService.get("vendor/paginate?" + payload)
                .then(({ data }) => {
                    context.commit(SET_VENDOR, data.data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message)
                    reject()
                })
        })
    },
    [SET_SINGLE_VENDOR](context, payload) {
        context.commit(SET_SINGLE_VENDOR, payload)
    },
    [UPDATE_VENDOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("vendor/" + payload._id, payload)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message)
                    reject()
                })
        })
    },
    [DELETE_VENDOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("vendor/" + payload)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message)
                    reject()
                })
        })
    },
    [IMPORT_VENDOR](context, payload) {
        const formData = new FormData()
        for (let key in payload) {
            formData.append(key, payload[key])
        }
        return new Promise((resolve, reject) => {
            ApiService.post(`import/school`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message)
                    reject()
                })
        })
    },
    [SEND_MAIL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`user/send-email`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_VENDOR](state, vendor_list) {
        state.vendorList = vendor_list;
    },
    [SET_SINGLE_VENDOR](state, payload) {
        state.vendor = payload;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
