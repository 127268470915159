<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
//body{
//  font-family: 'Quicksand';
//}

.card-table-row {
  display: table-row !important;
}

//.card-table table {
//  border-collapse: separate;
//  border-spacing: 0 10px;
//  margin-top: -10px; /* correct offset on first border spacing if desired */
//  table-layout: fixed;
//}
.card-table tr {
  td {
    border-bottom: 1px solid #ebedf3;
    padding: 10px;
  }
}

.card-table tr:last-child {
  td {
    border-style: none;
  }
}

//.card-table td:first-child {
//  border-left-style: solid;
//  border-top-left-radius: 0.42rem;
//  border-bottom-left-radius: 0.42rem;
//}
//.card-table td:last-child {
//  border-right-style: solid;
//  border-bottom-right-radius: 0.42rem;
//  border-top-right-radius: 0.42rem;
//}
@media (max-width: 991.98px) {
  .aside .aside-primary {
    width: 200px !important;
  }

  .aside-secondary-enabled .aside {
    width: 200px !important;
  }
}

.jawaban {
  .card {
    border-radius: 0;
    border-style: none;
    border-top-style: solid;

    &:first-child {
      border-top-left-radius: 0.42rem;
      border-top-right-radius: 0.42rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.42rem;
      border-bottom-right-radius: 0.42rem;
      border-bottom-style: solid;
    }
  }

  p {
    margin-bottom: 0 !important;
  }
}
</style>

<script>
/* eslint-disable */
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "MetronicVue",
  data() {
    return {
      tabFocus: true,
    };
  },
  computed: {
    ...mapGetters(["currentAttempt"]),
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.detectFocusOut();
  },
  methods: {
    detectFocusOut() {
      let inView = false;

      const onWindowFocusChange = (e) => {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (inView) return;
          this.tabFocus = true;
          inView = true;
        } else if (inView) {
          this.tabFocus = !this.tabFocus;
          inView = false;
          if (this.currentAttempt.soal)
            this.$set(
              this.currentAttempt,
              "tab_change",
              this.currentAttempt.tab_change + 1
            );
        }
      };

      window.addEventListener("focus", onWindowFocusChange);
      window.addEventListener("blur", onWindowFocusChange);
      window.addEventListener("pageshow", onWindowFocusChange);
      window.addEventListener("pagehide", onWindowFocusChange);
    },
  },
};
</script>
