import ApiService from "@/core/services/api.service";

// Actions
export const GET_DASHBOARD = "getDashboard";
export const GET_STUDENT_TABLE = "getStudentTable";

// Mutation
export const SET_DASHBOARD = "setDashboard";
export const SET_STUDENT_TABLE = "setStudentTable";
export const SET_ERROR = "setErrorDashboard";

const state = {
    dashboardList: [],
    studentTable: [],
    errors: null
};

const getters = {
    currentDashboardList(state) {
        return state.dashboardList;
    },
    currentStudentTable(state) {
        return state.studentTable;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_DASHBOARD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`dashboard`)
                .then(({ data }) => {
                    context.commit(SET_DASHBOARD, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_STUDENT_TABLE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`dashboard/exams-not-done?${payload}`)
                .then(({ data }) => {
                    context.commit(SET_STUDENT_TABLE, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
};

const mutations = {
    [SET_DASHBOARD](state, dashboard_list) {
        state.dashboardList = dashboard_list;
    },
    [SET_STUDENT_TABLE](state, student_table) {
        state.studentTable = student_table;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
